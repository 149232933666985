import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';
// "see_through": false,
// "image_url": "/_jsapps/backdrop/default_1024.jpeg",
// 'image_size': 1024,
// "image": '',
// "knockout_shape": "triangle",
// "knockout_blur": 0,
// "knockout": 85,
// "knockout_rotation": 171,
// "movement_direction": "xy",
// "xy_direction": 285,
// "layers": 3,
// "z_layers": 4,
// "speed": 30,
// "depth": 35,
// "base_rotation": 50,
// "skew": -50,
// "mouse_interaction": true,
// "mouse_speed": 68,
// "scroll_interaction": false,
// "color": "rgba(51,51,51,0)",
// "overlay_hex": '#ffffff',
// "overlay_alpha": 0,
// "hex_color": "#333333",
// "color_r": "51",
// "color_g": "51",
// "color_b": "51",
// "alpha": "0",
// "plugin_id": 16,
// "z_direction": "outwards"

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	// set these first so that they're in place when the changes that require callbacks run
	if( changes.hasOwnProperty('color_r') ){
		backdropView.Update('color_r', changes.color_r);
		delete changes.color_r
	}
	if( changes.hasOwnProperty('color_g') ){
		backdropView.Update('color_g', changes.color_g);
		delete changes.color_g
	}
	if( changes.hasOwnProperty('color_b') ){
		backdropView.Update('color_b', changes.color_b);
		delete changes.color_b
	}		

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);
		
	}
}

const settings = {
	name: 'parallax',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},		
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},	
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},		
		{
			labelName: 'Knockout Mask',
			name: "knockout_shape",
			type: "radio",
			value: "triangle",
			values: [
				{
					customLabel: 'RectangleIcon',
					value: 'rectangle'
				},
				{
					customLabel: 'SquareIcon',
					value: 'square'
				},
				{
					customLabel: 'TriangleIcon',
					value: 'triangle'
				},
				{
					customLabel: 'CircleIcon',
					value: 'circle'
				}
			],
		},
		{
			labelName: "Knockout Blur",
			name: "knockout_blur",
			type: "range-input",
			value: 0,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Knockout Size",
			name: "knockout",
			type: "range-input",
			value: 85,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Knockout Rotation",
			name: "knockout_rotation",
			type: "range-input",
			loopValues: true,
			value: 171,
			suffix: '°',
			min: 0,
			max: 360,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: undefined,
			name: "movement_direction",
			type: "radio",
			value: "xy",
			values: [
				{
					labelName: 'X/Y Axes',
					value:"xy"
				},
				{
					labelName: 'Z Axis',
					value:"z"
				}
			],
		},
			/* XY */
			{
				labelName: "Movement Direction",
				name: "xy_direction",
				type: "range-input",
				loopValues: true,
				value: 285,
				suffix: '°',
				min: 0,
				max: 360,
				step: 1,
				requirements: [
					{
						option: 'movement_direction',
						shouldBe: 'xy'
					}
				]
			},
			{
				labelName: "Layers",
				name: "layers",
				type: "range-input",
				value: 3,
				min: 2,
				max: 15,
				step: 1,
				requirements: [
					{
						option: 'movement_direction',
						shouldBe: 'xy'
					}
				]
			},
			/* Z */
			{
				labelName: undefined,
				name: "z_direction",
				type: "radio",
				value: "inwards",
				values: [
					{
						labelName: 'Inwards',
						value:"inwards"
					},
					{
						labelName: 'Outwards',
						value:"outwards"
					}
				],
				requirements: [
					{
						option: 'movement_direction',
						shouldBe: 'z'
					}
				]
			},
			{
				labelName: "Layers",
				name: "z_layers",
				type: "range-input",
				value: 4,
				min: 4,
				max: 15,
				step: 1,
				requirements: [
					{
						option: 'movement_direction',
						shouldBe: 'z'
					}
				]
			},
		{
			labelName: "Speed",
			name: "speed",
			type: "range-input",
			value: 30,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Depth",
			name: "depth",
			type: "range-input",
			value: 35,
			suffix: '%',
			min: 0,
			max: 100,
			step: 0.5
		},
		{
			labelName: "Base Rotation",
			name: "base_rotation",
			type: "range-input",
			loopValues: true,
			value: 50,
			suffix: '°',
			min: 0,
			max: 360,
			step: 1
		},
		{
			labelName: "Skew",
			name: "skew",
			type: "range-input",
			loopValues: true,
			value: -50,
			suffix: '°',
			min: -1080,
			max: 1080,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Mouse Sensitivity",
			name: "mouse_speed",
			type: "range-input",
			value: 68,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'mouse_interaction',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Scroll Interaction",
			name: "scroll_interaction",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Background Color",
			name: "background_color",
			type: "color",
			value: '#FFFFFF00',
		},
		{
			labelName: "See-Through",
			name: "see_through",
			type: "check-box",
			value: false
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#FFFFFF00',
			excludeFromShuffle: true,
		}
	]
}

settings.transformChanges = (settings, changes)=>{

	if( changes.hasOwnProperty("color") && changes?.color === null ){
		changes.color = 'rgba(0,0,0,0)';
	}

	if( changes.hasOwnProperty("background_color") && changes?.background_color === null ){
		changes.background_color = 'rgba(0,0,0,0)';
	}
	
	if(changes.color) {
		const overlay_color = tinycolor(changes.color);

		changes['overlay_hex'] = overlay_color.toHexString();
		changes['overlay_alpha'] = overlay_color.getAlpha();
	}

	if(changes.background_color) {
		const background_color = tinycolor(changes.background_color);
		const rgba = background_color.toRgb()
		changes['color_r'] = rgba.r
		changes['color_g'] = rgba.g
		changes['color_b'] = rgba.b
		changes['alpha'] = rgba.a
		changes['hex_color'] = background_color.toHexString();
	}

	return changes;
}
settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

export default settings;

export {onChangeCustom}
