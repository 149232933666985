import { createSelector as defaultCreateSelector, createSelectorCreator } from 'reselect';
import _ from 'lodash';
import * as helpers from "@cargo/common/helpers"
import { CRDTState } from "./globals";

const selectors = {};

let createSelector;

if(helpers.isServer) {
	// disable memoization on the server so data can't leak between requests
	createSelector = createSelectorCreator(func => func)
} else {
	createSelector = function() {
		return defaultCreateSelector.apply(this, [...arguments, {
			memoizeOptions: {
				maxSize: 10
			}
		}])
	}
}

selectors.getItemParentId = (state, itemId) => selectors.flatByParentList(state)[itemId];
selectors.getHomepageId = (state) => {

	if(state.frontendState.isMobile) {
		return state.site?.mobile_homepage_id;
	} else {
		return state.site?.homepage_id;
	}

	return null;

}

selectors.getHomepagePurl = (state) => {
	return selectors.getContentById(state, selectors.getHomepageId(state))?.purl?.toLowerCase();
}

selectors.getHiddenHomepageId = (state) => {

	const mobileHomepage = state.site?.mobile_homepage_id;
	const desktopHomepage = state.site?.homepage_id;

	if(mobileHomepage === desktopHomepage) {
		// both homepages are the same. There's no hidden homepage
		return null;
	}

	if(state.frontendState.isMobile) {

		// if we have a desktop homepage that differs from the mobile homepage,
		// and we are in mobile mode, the desktop homepage id should be hidden
		return desktopHomepage;

	} else {

		// if we have a mobile homepage that differs from the desktop homepage,
		// and we are in desktop mode, the mobile homepage id should be hidden
		return mobileHomepage;

	}

}

// Returns a flat list of {id: parent_id}
selectors.flatByParentList = createSelector(
	state => state.structure.byParent,
	// compile the result
	(itemsByParent) => {

		const flatMap = {};

		for ( const parentId in itemsByParent ){

			for( const childIDIndex in itemsByParent[parentId] ) {
				flatMap[itemsByParent[parentId][childIDIndex]] = parentId;
			}

		}

		return flatMap;

	}
)

selectors.getSetsByParent = createSelector(
	state => state.structure.byParent,
	state => state.sets.byId,
	// compile the result
	(itemsByParent, setsById) => {

		const setsByParent = {};

		for ( const parentId in itemsByParent ){
			setsByParent[parentId] = itemsByParent[parentId].filter(childId => setsById.hasOwnProperty(childId))
		}

		return setsByParent;

	}
)


selectors.getPagesByParent = createSelector(
	state => state.structure.byParent,
	state => state.pages.byId,
	// compile the result
	(itemsByParent, pagesById) => {

		const pagesByParent = {};

		for ( const parentId in itemsByParent ){
			pagesByParent[parentId] = itemsByParent[parentId].filter(childId => pagesById.hasOwnProperty(childId))
		}

		return pagesByParent;

	}
)

selectors.getParentSetList = createSelector(
	selectors.getItemParentId,
	selectors.flatByParentList,
	// compile the result
	(parentSetId, flatByParentList) => {

		const result = [];

		while(parentSetId) {

			// add parent to stack
			result.push(parentSetId);

			// find next parent
			parentSetId = parentSetId !== flatByParentList[parentSetId] ? flatByParentList[parentSetId] : null;

		}

		return result;

	}
)

selectors.getSetContents = createSelector(
	// retrieve the set's id
	(state, itemId) => itemId,
	// grab the homepage id
	selectors.getHomepageId,
	// retrieve indexableOnly bool
	(state, itemId, options) => options,
	// get all sorts
	(state, setId, position) => state.structure.bySort,
	// Retrieve all pages & sets in this set
	(state, itemId) => state.structure.byParent[itemId],
	// retrieve a list of all pages
	(state) => state.pages.byId,
	// retrieve a list of all sets
	(state) => state.sets.byId,
	// compile the result
	(itemId, homepageId, options = {}, sortMap, itemIds = [], allPages, allSets) => {

		let content;

		return _.without(
			itemIds.map(id => {

				content = allPages[id] || allSets[id];

				if(
					options.includeNotIndexable === true 
					|| (
						content && (content.id === homepageId || helpers.contentIsIndexable(content))
					)
				) {
					return content
				}

			}).sort((a,b) => sortMap[a.id] - sortMap[b.id]),
			undefined
		)
	}
)

selectors.getSetContentsByIndex = createSelector(
	// get contents of set
	selectors.getSetContents,
	// get all indexes
	(state, setId, position) => state.structure.indexById,
	(setContents, indexById) => {

		return setContents.reduce((acc, content) => {
			acc[indexById[content.id]] = content;
			return acc;
		}, {});

		
	}
)

selectors.getSetDepthsById = createSelector(
	// get all set ids
	(state) => state.sets.byId,
	selectors.flatByParentList,
	(setsById, flatByParentList) => {

		const depthMap = {};

		Object.keys(setsById).forEach(setId => {

			let depth = 0;
			let parentSetId = setId;

			while(flatByParentList[parentSetId]) {
				depth++;
				parentSetId = flatByParentList[parentSetId];
			}

			depthMap[setId] = depth;

		})

		return depthMap;
		
	}
)

selectors.getPinsForSet = createSelector(
	// get list of parents
	selectors.getParentSetList,
	// get depths of sets
	selectors.getSetDepthsById,
	// retrieve the set's id
	(state, setId, position, activePID) => setId,
	// retrieve position of the pins
	(state, setId, position, activePID) => position,
	// retrieve the currently rendered PID
	(state, setId, position, activePID) => activePID,
	// get all sorts
	(state, setId, position, activePID) => state.structure.bySort,
	// get all pages
	(state, setId, position, activePID) => state.pages.byId,
	// get all pages by their parents
	selectors.getPagesByParent,
	// check if we're on mobile
	state => state.frontendState.isMobile,
	// compile the result
	(parentSetList, setDepthsById, setId, position, activePID, sortMap, pagesById = {}, pagesByParent = {}, isMobile) => {

		if(!setId) {
			return [];
		}

		let parentSetId = setId;
		let depth = 0;

		const depthMap = {};
		const pins = [];

		// add the current set to the list as we want to find pins in this set + it's parents.
		// not mutating parentSetList because it's the result of a memoized function and will 
		// affect subsequent calls.
		const setsToCheck = parentSetList.concat([setId]);

		setsToCheck.forEach((setId) => {

			// find all pages belonging to this set
			if(pagesByParent[setId]) {
				
				// check if they are a pin or not
				pagesByParent[setId].forEach((pageId) => {

					if(
						pagesById[pageId] && 
						pagesById[pageId].pin && 
						pagesById[pageId].pin_options &&
						pagesById[pageId].pin_options.position === position
					) {

						// Only check mobile/desktop for a pin that's not the active PID. When directly loading a
						// pin we always want to show it regardless of mobile/desktop visibility
						if(pageId !== activePID) {

							if(pagesById[pageId].pin_options.screen_visibility === 'mobile' && !isMobile) {
								// mobile pin but we're not on mobile
								return;
							}

							if(pagesById[pageId].pin_options.screen_visibility === 'desktop' && isMobile) {
								// desktop pin but we're on mobile
								return;
							}

						}

						pins.push(pagesById[pageId]);

						// all but fixed pins get binned by parent set depth
						depthMap[pageId] = pagesById[pageId].pin_options.fixed ? 0 : setDepthsById[setId];

					}

				})

			}

		})

		// first sort pins by their sort position
		pins.sort((a, b) => {

			let sortA = sortMap[a.id];
			let sortB = sortMap[b.id];

			if(a.pin_options.fixed) {
				// fixed goes all the way to the top
				sortA += position === 'bottom' ? +9e9 : -9e9;
			} else if(a.pin_options.overlay) {
				// overlay goes just below
				sortA += position === 'bottom' ? +9e5 : -9e5;
			}

			if(b.pin_options.fixed) {
				sortB += position === 'bottom' ? +9e9 : -9e9;
			} else if(b.pin_options.overlay) {
				// overlay goes just below
				sortB += position === 'bottom' ? +9e5 : -9e5;
			}

			return sortA - sortB;

		});

		// then sort by depth
		pins.sort((a, b) => {
			if(position === 'bottom') {
				return depthMap[b.id] - depthMap[a.id]
			} else {
				return depthMap[a.id] - depthMap[b.id]
			}
		});

		return pins;
	}
)

selectors.getAutoRenderableOverlaysForSet = createSelector(
	// get list of parents
	selectors.getParentSetList,
	// get depths of sets
	selectors.getSetDepthsById,
	// retrieve the set's id
	(state, setId, activePagePurl) => setId,
	// retrieve page purl for the current route
	(state, setId, activePagePurl) => activePagePurl,
	// get all pages
	(state, setId, activePagePurl) => state.pages.byId,
	// get PID that needs editing
	(state, setId, activePagePurl) => state.frontendState.PIDToEdit,
	// get all pages by their parents
	selectors.getPagesByParent,
	// compile the result
	(parentSetList, setDepthsById, setId, activePagePurl, pagesById = {}, PIDToEdit, pagesByParent = {}) => {

		if(!setId || helpers.isServer) {
			return [];
		}

		let parentSetId = setId;
		let depth = 0;

		const overlays = [];

		// add the current set to the list as we want to find pins in this set + it's parents.
		// not mutating parentSetList because it's the result of a memoized function and will 
		// affect subsequent calls.
		const setsToCheck = parentSetList.concat([setId]);

		setsToCheck.forEach((setId) => {

			// find all pages belonging to this set
			if(pagesByParent[setId]) {
				
				// check if they are an overlay or not
				pagesByParent[setId].forEach((pageId) => {

					if(
						pagesById[pageId]
						&& pagesById[pageId].overlay
						&& (
							// render if the page needs to be edited
							pagesById[pageId].id === PIDToEdit
							// or if it should automatically render
							|| pagesById[pageId].overlay_options?.openOnLoad
							// or the overlay has been directly linked
							|| pagesById[pageId].purl?.toLowerCase() === activePagePurl?.toLowerCase()
						)
					) {
						overlays.push(pagesById[pageId]);
					}

				})

			}

		});

		return overlays;
	}
)

// Looks for a set or page by PID.
selectors.getContentById = createSelector(
	// retrieve the id
	(state, contentId) => contentId,
	// Retrieve page or set by url or id
	(state, contentId) => 
		state.pages.byId[contentId] 
		|| state.sets.byId[contentId],	// compile the result
	(contentId, content) => {
		
		return content;
		
	}
)

// Looks for a set or page by PURL.
selectors.getContentByPurl = createSelector(
	// retrieve the id
	(state, purl) => purl,
	// Retrieve page or set by url or id
	(state, purl) => 
		state.pages.byId[selectors.getPagesByUrl(state)[purl?.toLowerCase()]]
		|| state.sets.byId[selectors.getSetsByUrl(state)[purl?.toLowerCase()]],
	// compile the result
	(purl, content) => {
		
		return content;
		
	}
)

// Returns all indexes for which the set does not have data loaded
selectors.getLoadedIndexesForSet = createSelector(
	selectors.getSetContents,
	(state) => state.structure.indexById,
	// compile the result
	(setItems, indexById) => {

		return _.without(setItems.map(content => {

			if(helpers.contentIsIndexable(content)) {
				return indexById[content.id]
			}

		}), undefined)
		
	}
)

// Returns all indexes for which the set does not have data loaded
selectors.getMissingIndexesForSet = createSelector(
	(state, id) => state.sets.byId[id],
	selectors.getLoadedIndexesForSet,
	// compile the result
	(set, loadedIndexes) => {

		if(!set) {
			return [];
		}

		return _.difference([...Array(Math.max(set.page_count || 0, 0)).keys()], loadedIndexes)
		
	}
)

// Returns all indexes for which the set does not have data loaded
selectors.getSortedListOfIndexPairs = createSelector(
	state => state,
	(state, setId) => setId,
	(state, setId) => state.sets.byId,
	(state, setId) => state.structure.indexById,
	selectors.getSetsByParent,
	// compile the result
	(state, startingSetId, setsById, indexById, setsByParent) => {

		const recursivelyGetIndexesForSet = (setId) => {

			const set = selectors.getContentById(state, setId)

			if(!set) return [];
			
			// get all missing indexes for this set. Then map them to a [set, index] pair so we know what
			// set every index belongs to (we can return indexes for many nested sets)
			const indexesInSet = [...Array(Math.max(set.page_count || 0, 0)).keys()].map(missingIndex => [set.id, missingIndex]);

			// no nested sets in this set. Just return it's missing indexes
			if(!setsByParent[set.id]) {
				return indexesInSet;
			}

			(setsByParent[set.id] || []).map(id => setsById[id]).reverse().forEach(nestedSet => {
				indexesInSet.splice(indexById[nestedSet.id] + 1, 0, recursivelyGetIndexesForSet(nestedSet.id))
			})

			return indexesInSet;

		}

		return _.chunk(
			// flatten the nested array into one long sequentually ordered list
			_.flattenDeep(
				recursivelyGetIndexesForSet(startingSetId)
			)
		, 2) // chunk together in pairs of 2 [setId, index]

	}
)

// Returns all indexes for which the set does not have data loaded
selectors.getSortedListOfMissingIndexPairs = createSelector(
	state => state,
	selectors.getSortedListOfIndexPairs,
	(state, setId) => state.sets.byId,
	selectors.getSetsByParent,
	// compile the result
	(state, allIndexPairs, setsById, setsByParent) => {

		let unsortedLoadedPairs = [];

		// loop over every unique set id that occurs within the 
		// full list of pages inside of this set
		_.uniq(allIndexPairs.map(pair => pair[0])).forEach(setId => {

			// for every set, find all the indexes that have been loaded already
			unsortedLoadedPairs = unsortedLoadedPairs.concat(
				selectors.getLoadedIndexesForSet(state, setId).map(index => [setId, index])
			)

		});
			
		// return the list of only the indexes that have not been loaded
		return  _.differenceWith(allIndexPairs, unsortedLoadedPairs, _.isEqual);

	}
)

// Returns all indexes for which the set does not have data loaded
selectors.getGappedSetMap = createSelector(
	state => state,
	selectors.getSortedListOfIndexPairs,
	selectors.getSortedListOfMissingIndexPairs,
	// compile the result
	(state, allIndexPairs, missingIndexPairs) => {

		// get the first gap
		const firstMissingIndex = missingIndexPairs[0];

		if(firstMissingIndex) {

			// create a default map for all sets with a gap at index 0
			const defaults = _.uniq(allIndexPairs.map(pair => pair[0])).reduce((map, setId) => {
				map[setId] = 0;
				return map;
			}, {});

			// find where in allIndexPairs the first gap occurs
			const missingPairIndex = _.findIndex(
				allIndexPairs, 
				pair => _.isEqual(pair, firstMissingIndex)
			);

			// the map of set ids with the proper index of the first gap
			const gappedSetMap = _.fromPairs(allIndexPairs.slice(0, missingPairIndex));

			// merge the map contains gapped sets into the default map and return
			return _.merge(defaults, gappedSetMap)

		}

		return {};

	}
)

selectors.getPagesByUrl = createSelector(
	state => state.pages.byId,
	// compile the result
	(pagesById) => {

		return _.transform(_.omitBy(pagesById, page => page.crdt_state === CRDTState.Deleted), function(result, page) {
			result[page.purl?.toLowerCase()] = page.id
		}, {});

	}
)

selectors.getSetsByUrl = createSelector(
	state => state.sets.byId,
	// compile the result
	(setsById) => {

		return _.transform(_.omitBy(setsById, set => set.crdt_state === CRDTState.Deleted), function(result, set) {
			result[set.purl?.toLowerCase()] = set.id
		}, {});

	}
)

selectors.getMediaByHash = (state) => {

		const map = {};

		_.each(state.pages.byId, page => {

			_.each(page.media, file => {
				if(file.crdt_state !== CRDTState.Deleted) {
					map[file.hash] = file;
				}
			});

		});
		
		return map;

}

selectors.getMediaByParent = createSelector(
	state => state.pages.byId,
	// compile the result
	(pagesById) => {

		const map = {};

		_.each(pagesById, page => {
			map[page.id] = page.media?.filter(file => file.crdt_state !== CRDTState.Deleted);
		});
		
		return map;

	}
)

selectors.getRenderedPageModels = createSelector(
	state => state.pages.byId,
	state => state.frontendState.renderedPages,
	// compile the result
	(pagesById, renderedPages) => {
		return renderedPages.map(page => pagesById[page.id]).filter(page => page !== undefined);
	}
)

export default selectors