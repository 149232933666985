import { Component , cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat'
import { ADMIN_FRAME, FRONTEND_DATA } from "../../globals";
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller"

import _, { map } from 'lodash';

import SelectionStatus from "./selection-status";

class MapEditor extends Component {
	constructor(props){
		super(props);

		this.state= {
			buttonHovered: false,
			UIWindow: null,
			focused: false,
			isDraggingInEditor: false,
			initialShowOutlines: false,
			showTransition: false,
			draggingOver: false,
			showInsertionIndicator: false,
			map: null,
			markers: [],
		}

		this.onMutate = _.debounce(this.onMutate, 0);
		this.changeObserver = new MutationObserver(this.onMutate);

	}

	handleMarkerDragEnd = (e) => {

		const { index, lat, lng } = e.detail;

		if (isNaN(lat) || isNaN(lng)) {
			return;
		}

		const newMarkers = [...this.state.markers];
		newMarkers[index] = {
			...newMarkers[index],
			lat,
			lng
		};
		CargoEditor.mutationManager.execute(() => {
			this.state.map.setAttribute('markers', encodeURIComponent(JSON.stringify(newMarkers)))
		});
	}

	onChange (changes) {
		CargoEditor.mutationManager.execute(() => {
				if( Object.keys(changes)[0] === 'markers' ){
					if (!this.state.markers || changes['markers'].length !== this.state.markers.length) {
						this.state.map.setAttribute('markers', encodeURIComponent(JSON.stringify(changes['markers'])) )
					} else {
						const newMarkers = this.state.markers.map((existingMarker, index) => {
							if (changes['markers'][index]) {
								return {
									...existingMarker,
									...changes['markers'][index]
								}
							}
							return existingMarker;
						});
						console.log('newMarkers', newMarkers);
						this.state.map.setAttribute('markers', encodeURIComponent(JSON.stringify(newMarkers)))
					}
				}
			})
	}

	render(props,state){
		const {
			mapInstance,
		} = props;

		const {
			draggingOver,
			showInsertionIndicator,
			buttonHovered,
			focused,
		} = state;		
		const buttonHeight = 23;

		return (
			<>
				<style id="map-editor">
					{`::selection{
						background-color:transparent;
					}
					:host(::selection) {
						background-color:transparent;
					}`}
				</style>
			
				<EditorOverlay
					hasFocus={focused}
					onScrollWhileHovered={this.updateUIWindowPosition}				
					trackResize={true}
					baseNode={this.props.mapInstance}
					render={(overlayProps)=>{

						const {
							pointerAttention,
							overlayPosition
						} = overlayProps;

						let dip = 0;
						if( overlayPosition.y <= 1 ){
							dip = Math.min(-overlayPosition.y +1, overlayPosition.height + -buttonHeight +1);
						}								

						return <>
						
						<div
							className={`editor-overlay ${pointerAttention ? ' button-hover': ''}${focused ? ' focus': ''}`}
							style={{
								transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
								width: overlayPosition.width +'px',
								height: overlayPosition.height+'px',
								display: (pointerAttention || buttonHovered || focused) ? 'block': 'none',
								minHeight: '27px'
							}}
						>
							<div
								className="in-editor-buttons map"
								style={{
									transform: `translateY(${dip}px)`,
									padding: '10px',
								}}
								onPointerEnter={()=>{this.setState({buttonHovered: true})}}
								onPointerLeave={()=>{this.setState({buttonHovered: false})}}
							>
								<button
									onMouseDown={(e)=>{
										const center = this.state.map.map.getCenter();
										const purl = '';
										const lat = center.lat();
										const lng = center.lng();
										const newMarkers = this.state.markers ? [...this.state.markers, {
											purl,
											lat,
											lng
										}] : [{
											purl,
											lat,
											lng
										}];
										this.onChange({markers: newMarkers});
										const markerIndex = newMarkers.length - 1;
										setTimeout(() => {
											this.state.map.dispatchEvent(new CustomEvent('marker-click', {
												detail: {
													index: newMarkers.length - 1,
													clientY: null,
													clientX: null,
												}
											}));
										}, 200);
									}}
									ref={this.MapButtonRef}
									className="text"
								>
									Add Marker
								</button>
								<button
									onMouseDown={(e)=>{this.launchMapUIWindow(e)}}
									ref={this.MapButtonRef}
									className="text"
								>
									Settings
								</button>
								{props.children}
							</div>
							{props.otherUI}
						</div>
						</>
				}}/>

			</>)
	}

	handleMarkerClick = (e) => {
		this.launchMarkerUIWindow(e.detail);
	}

	launchMarkerUIWindow = ({clientX, clientY, index})=>{

		const markerEl = this.state.map?.shadowRoot?.querySelectorAll(`.marker-${index}`)[0];
		const rect = markerEl.getBoundingClientRect();

		//const rect = this.props.mapInstance.getBoundingClientRect();
		const svgPosition = {
			x: rect.x,
            y: rect.y,
			left: rect.right,
			right: rect.right,
			top: rect.top - 10,
			bottom: rect.top + 27,
			width: rect.width,
			height: 10
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'map-ineditor',
			windowAlias: 'map',
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			supportsMobile: false,
			props: {
				mapNode: this.props.mapInstance,
                markerIndex: index,
			}
		});		
	}

	launchMapUIWindow = (e, galleryRect)=>{

		if (e) {
			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const rect = this.props.mapInstance.getBoundingClientRect();
		const svgPosition = {
			x: rect.x - 10,
			y: rect.y,
			left: rect.right - 10,
			right: rect.right - 10,
			top: rect.top - 10,
			bottom: rect.top + 27,
			width: rect.width,
			height: 10
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'map-ineditor',
			windowAlias: 'map',
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			supportsMobile: false,
			props: {
				mapNode: this.props.mapInstance
			}
		});		
	}	

	scrollIntoView =()=>{

		const rect = this.props.mapInstance.getBoundingClientRect();
		
		if (rect.bottom < 0 || rect.top > window.innerHeight){
			this.props.mapInstance.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});	
		}		

	}

	updateUIWindowPosition = (scrollDelta)=>{


		if( this.props.onScrollWhileHovered ){
			this.props.onScrollWhileHovered(scrollDelta)
		}

		if(!this.state.UIWindow){
			return;
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta)

	}

	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow,
			focused: !!UIWindow,
		})
	}

	onMutate = () => {
		this.getSettingsFromNodes();
	}

	getSettingsFromNodes() {
		this.setState({
			...this.state,
			map: this.props.mapInstance,
			markers: JSON.parse(decodeURIComponent(this.props.mapInstance.getAttribute('markers') || '[]')),
		})
	}

	componentDidUpdate = (prevProps, prevState) => {
		this.changeObserver.disconnect();
		this.changeObserver.observe(this.state.map, { attributes: true })
	}

	componentDidMount = () => {
		this.props.mapInstance._editorInterface = this;

		this.canMutateTimeout = setTimeout(()=>{
			this.setState({
				...this.state,
				canMutate: true,
			})
		}, 300);

		this.getSettingsFromNodes();

		this.props.mapInstance.addEventListener('marker-dragend', this.handleMarkerDragEnd);
		this.props.mapInstance.addEventListener('marker-click', this.handleMarkerClick);

	}

	componentWillUnmount = () => {
		this.props.mapInstance.removeEventListener('marker-dragend', this.handleMarkerDragEnd);
		this.props.mapInstance.removeEventListener('marker-click', this.handleMarkerClick);
		this.state.UIWindow?.closeWindow?.();
		delete this.props.mapInstance._editorInterface
		clearTimeout(this.canMutateTimeout);
		this.onMutate.cancel();
		this.changeObserver.disconnect();
	}

}

export default MapEditor
